.projects-header {
    margin-top: 3rem;
    text-align: center;
    font-size: 3rem;
    font-family: 'Roboto Slab', serif;
}

.card {
    margin-top: 1rem;
    max-width: 27rem;
    min-width: 27rem;
}

.links {
    text-align: center;
}

#project-par {
    font-family: 'Roboto Slab', serif;
}

.sentence-bold {
    font-weight: bolder;
    text-decoration: underline;
}

.skills-header {
    margin-top: 3rem;
    text-align: center;
    font-size: 3rem;
    font-family: 'Roboto Slab', serif;
}

#skills-title {
    font-size: 2rem;
    text-decoration: underline;
    font-family: 'Roboto Slab', serif;
}

#skills-item {
    font-size: 1rem;
    font-family: 'Roboto Slab', serif;
}

#column-skills {
    text-align: center;
    font-family: 'Roboto Slab', serif;
}
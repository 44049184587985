.about-header {
    margin-top: 3rem;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 3rem;
    font-family: 'Roboto Slab', serif;
}

.notification {
    background-color: white;
}

.self-photo {
    max-width: 30rem;
}

.about-footer {
    text-align: center;
}

#about-buttons {
    justify-content: center;
}

#about-box {
    font-family: 'Roboto Slab', serif;
}

.box {
    max-height: 40rem;
}